import { useDispatch, useSelector } from 'react-redux';

import {
  discardVehicleValue,
  isolatedDiscardVehicle,
  replaceVehicleValue,
} from 'Core/actions/fitmentSearch/index.js';
import { getIsolatedKeyFromWidgetName } from 'Addons/fitmentSearch/isolatedKeys';
import {
  createAllRequiredFieldsHaveSelectionSelector,
  createFitmentSearchResponseFacetsSelector,
  fitmentSearchFieldsSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import { cloneSafe } from 'Utils/components.ts';

import type { FunctionComponent } from 'react';
import type { IsolatedKey } from 'Addons/fitmentSearch/isolatedKeys.ts';
import type {
  RepeaterFunctionInvoker,
  TemplateFunction,
  TemplateFunctionInvoker,
  TemplateResult,
} from 'Components/types.ts';
import type { Facet } from 'Models/index.ts';

interface Chip {
  field: string;
  value: string;
  discardValue: () => void;
}
interface Chips {
  chipsRepeater: RepeaterFunctionInvoker<Chip>;
  discardVehicle: () => void;
}

interface VehicleTile {
  value: string;
  term: string;
}
interface TileContainer {
  field: string;
  selectedFields: string[];
  tiles: RepeaterFunctionInvoker<VehicleTile>;
}

interface Params {
  tileContainers: RepeaterFunctionInvoker<TileContainer>;
  chips: TemplateFunctionInvoker<Chips>;
}

type Props = {
  template: TemplateFunction<Params>;
  name: string;
};

const VehicleTiles: FunctionComponent<Props> = ({ template, name }) => {
  const dispatch = useDispatch();

  const isolatedKey = getIsolatedKeyFromWidgetName(name) as IsolatedKey;
  const fitmentSearchFacets = useSelector(createFitmentSearchResponseFacetsSelector(isolatedKey)) as Facet[];
  const fitmentFields = useSelector(fitmentSearchFieldsSelector);
  const isAllRequiredFieldHasSelection = useSelector(
    createAllRequiredFieldsHaveSelectionSelector(null),
  ) as boolean;

  if (!fitmentSearchFacets?.length) {
    return null;
  }

  const selectedFacets = fitmentSearchFacets.filter((f) => !!f.selection.length);
  const selectedFields = selectedFacets.map((f) => f.field);

  const chips: TemplateFunctionInvoker<Chips> = (template) => {
    if (!selectedFacets?.length) {
      return null;
    }

    const chipsRepeater = selectedFacets.map((facet) => (template) => {
      const [selectedValue] = facet.selection;
      const discardValue = () => {
        dispatch(discardVehicleValue(selectedValue));
      };

      const component = template.call({ field: facet.field, value: selectedValue.value, discardValue });

      return cloneSafe(component, null);
    }) as RepeaterFunctionInvoker<Chip>;

    const discardVehicle = () => {
      dispatch(isolatedDiscardVehicle(null));
      if (isAllRequiredFieldHasSelection) {
        fitmentSearchConfig.onVehicleDiscarded();
      }
    };

    const component = template.call({ chipsRepeater, discardVehicle });

    return cloneSafe(component, null) as TemplateResult;
  };

  const tileContainers = fitmentSearchFacets.map(({ field, values }) => (template) => {
    const tilesRepeater = values.map((value) => (templ) => {
      const onClick = () => {
        dispatch(replaceVehicleValue(value, undefined, fitmentFields));
      };

      const component = templ.call({ value: value.value, term: value.term });

      return cloneSafe(component, null, { onClick });
    }) as RepeaterFunctionInvoker<VehicleTile>;

    const component = template.call({ field, selectedFields, tiles: tilesRepeater });

    return cloneSafe(component, null);
  }) as RepeaterFunctionInvoker<TileContainer>;

  return template.call({ tileContainers, chips });
};

export default VehicleTiles;
