export default {
  BUTTON_PRIMARY_CLASS: 'button',
  BUTTON_SECONDARY_CLASS: 'button',

  CURRENT_SEARCH: 'CURRENTLY SHOPPING BY',
  START_OVER: 'Clear All',

  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_PLACEHOLDER:
    "{this.name === 'SearchBox_hometab-part-number' ? 'Enter Part Number' : this.name === 'SearchBox_hometab-vin' ? 'Search By VIN' : `Search the world's largest lift support catalog`}",
  SEARCH_BOX_SECTION_TITLE: "<b>{this.totalHits > 1 ? `${this.totalHits} ` : ''}{{sectionTitle}}</b>",

  GARAGE_SIZE: '<span class="garage-size countPill countPill--positive" key="garage-size">{{size}}</span>',

  CHANGE_VEHICLE: 'Change Vehicle',
  DISCARD_VEHICLE: 'Clear',
};
