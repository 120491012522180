
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'cm_search-by-part-number__search-page' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '7050'
                }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '10350'
                }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1568'
            }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1982'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2236'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2554'
            }, _createElement('div', {}, window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '26401' }, '\n      No results were found\n      ', this.vehicleString && !this.query ? [
                    '\n        for your ',
                    _createElement('b', { 'key': '27411' }, '\'', this.vehicleString, '\'.')
                ] : null, this.query ? [
                    ' for the entered VIN number ',
                    _createElement('b', { 'key': '28881' }, '\'', this.query, '\'.')
                ] : null)] : null, !window.Convermax.isSearchByYMMTPage ? [_createElement('div', { 'key': '30241' }, '\n      No results were found for your\n      ', this.query ? [
                    ' query ',
                    _createElement('b', { 'key': '31351' }, '\'', this.query, '\'.')
                ] : null, !this.query ? [
                    ' query ',
                    _createElement('b', { 'key': '32261' }, '\'', this.search, '\'.')
                ] : null)] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3354'
            }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3573'
            }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3817'
            }, 'Your search request failed to process\n', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '39680' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '40550' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '41380' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '39334' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'part-number' }, _createElement('p', { 'className': 'text-center' }, 'Search using the OEM part number printed on your OE lift.'), [this.PartNumberSearchBox(function () {
            return _createElement('div', { 'className': 'cm_search-box-root__part-number cm_PartNumberSearchBox' }, _createElement('form', { 'onSubmit': this.preventFormSubmission }, _createElement('div', { 'className': 'cm_search-box_form-container' }, [this.searchInput(function () {
                    return _createElement('div', { 'className': 'form-input cm_searchInput' }, 'Enter Part Number');
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('span', { 'className': 'cm_search-box_clear-container' }, this.inputNotEmpty ? _createElement('span', {
                'className': 'cm_search-box_clear',
                'onClick': this.clearInput,
                'key': '500'
            }, '\n              ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n            ') : null)), _createElement('button', {
                'data-cm-role': 'add-query',
                'className': 'button'
            }, 'Search')));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('p', { 'className': 'text-center' }, _createElement('small', {}, _createElement('b', {}, 'Example:'), ' F85-278-406A-11AA ', _createElement('b', {}, 'Enter Above:'), ' F85278406A11AA'))), this.responseQuery && this.totalHits > 0 ? _createElement('h1', {
        'className': 'page-heading search-heading',
        'key': '9595'
    }, '\n    Search Results for "', _createElement('strong', {}, this.responseQuery), '"\n  ') : null, [_createElement('div', {
            'id': 'itemsBlock',
            'className': this.totalHits === 0 || this.isLoading ? 'cm_hide' : '',
            'key': '97790'
        }, _createElement('p', { 'className': 'results-info' }, '\n    Items ', _createElement('b', {}, this.pageFrom), ' to ', _createElement('b', {}, this.pageTo), ' of a total ', _createElement('b', {}, this.totalHits)), [this.SearchHeader(function () {
                return _createElement('div', { 'className': 'cm_search-by-part-number__search-header cm_SearchHeader' }, _createElement('div', { 'className': 'listing-header group' }, _createElement('div', { 'className': 'cm_search-by-size__search-header_left' }, [this.pagination(function () {
                        function repeatButtons1(buttons, buttonsIndex) {
                            return [buttons(function () {
                                    return this.template === 'prev' ? _createElement('li', { 'className': 'cmTemplate_prev' }, _createElement('a', { 'href': this.pageLink }, _createElement('i', {
                                        'className': 'icon',
                                        'aria-hidden': 'true'
                                    }, _createElement('svg', {}, _createElement('use', {
                                        'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                        'xlinkHref': '#icon-chevron-left'
                                    }))))) : this.template === 'next' ? _createElement('li', { 'className': 'cmTemplate_next' }, _createElement('a', { 'href': this.pageLink }, _createElement('i', {
                                        'className': 'icon',
                                        'aria-hidden': 'true'
                                    }, _createElement('svg', {}, _createElement('use', {
                                        'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                        'xlinkHref': '#icon-chevron-right'
                                    }))))) : this.template === 'page' ? _createElement('li', { 'className': 'cmTemplate_page' }, this.isActive ? _createElement('a', {
                                        'href': this.pageLink,
                                        'className': 'active',
                                        'key': '33'
                                    }, this.page) : null, !this.isActive ? _createElement('a', {
                                        'href': this.pageLink,
                                        'key': '116'
                                    }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_pagination' }, _createElement('label', { 'className': 'form-label' }, 'Pages:'), _createElement.apply(this, [
                            'ul',
                            { 'className': 'custom-pagination cmRepeater_buttons' },
                            _map(this.buttons, repeatButtons1.bind(this))
                        ]));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })], _createElement('div', { 'className': 'cm_unitstoggle sort-fieldset' }, _createElement('label', {
                    'className': 'form-label',
                    'htmlFor': 'sort'
                }, 'Display In:'), _createElement('select', {
                    'className': 'form-select form-select--small form-select-narrow',
                    'onChange': e => this.setView(e.target.value)()
                }, _createElement('option', {
                    'value': 'Inches',
                    'selected': this.view === 'Inches'
                }, 'Inches'), _createElement('option', {
                    'value': 'Millimeters',
                    'selected': this.view === 'Millimeters'
                }, 'Millimeters'), _createElement('option', {
                    'value': 'Both',
                    'selected': this.view === 'Both'
                }, 'Both')))), _createElement('div', { 'className': 'cm_sort sort-fieldset' }, _createElement('label', { 'className': 'form-label' }, 'Sort by: '), '\n    ', this.sortSelect, '\n  '), _createElement('div', { 'className': 'cm_itemsperpage sort-fieldset' }, _createElement('label', { 'className': 'form-label' }, 'SHOW:'), '\n    ', this.pageSizeSelect, '\n  '), [this.facetDialogButton(function () {
                        return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                                return _createElement('div', { 'className': 'button cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                        'className': 'cm_icon cm_filter-icon',
                                        'viewBox': '-5 0 394 394.00003',
                                        'key': '520'
                                    }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                        'className': 'cm_icon cm_filter-icon',
                                        'viewBox': '0 0 247.46 247.46',
                                        'key': '7930'
                                    }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })]);
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]));
            }, {
                widgetName: 'search-header',
                items: undefined
            })], [this.SearchResult(function () {
                function repeatItems1(items, itemsIndex) {
                    return [items(function () {
                            function repeatInterchange1(interchange, interchangeIndex) {
                                return [
                                    _createElement('span', Object.assign({}, { 'key': '161' }, { dangerouslySetInnerHTML: { __html: interchange } })),
                                    interchangeIndex < this.A_Interchanges.length - 1 ? [_createElement('br', { 'key': '1571' })] : null
                                ];
                            }
                            function onSubmit2(e) {
                                {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }
                            function onClick3(e) {
                                window.Convermax.addToCart(e);
                            }
                            return _createElement('tr', {}, _createElement.apply(this, [
                                'td',
                                {},
                                _map(this.A_Interchanges, repeatInterchange1.bind(this))
                            ]), _createElement('td', {}, this.brand_image ? _createElement('img', {
                                'className': 'card-image-brand',
                                'src': this.imageOrDefault(this.brand_image),
                                'alt': this.removeHTML(this.title),
                                'onError': this.onImageError,
                                'key': '302'
                            }) : null), _createElement('td', {}, _createElement('u', {}, _createElement('a', Object.assign({}, { 'href': this.custom_url }, { dangerouslySetInnerHTML: { __html: this.sku } })))), this.view !== 'Millimeters' ? _createElement('td', { 'key': '595' }, this.Extended_Length_A ? this.Extended_Length_A : '0.00') : null, this.view !== 'Inches' ? _createElement('td', { 'key': '722' }, '\n    ', this.Extended_Length_A ? (this.Extended_Length_A * 25.4).toFixed(2) : '0.00', '\n  ') : null, this.view !== 'Millimeters' ? _createElement('td', { 'key': '872' }, this.Stroke_C ? this.Stroke_C : '0.00') : null, this.view !== 'Inches' ? _createElement('td', { 'key': '981' }, this.Stroke_C ? (this.Stroke_C * 25.4).toFixed(2) : '0.00') : null, this.view !== 'Millimeters' ? _createElement('td', { 'key': '1105' }, this.Compressed_Length_B ? this.Compressed_Length_B : '0.00') : null, this.view !== 'Inches' ? _createElement('td', { 'key': '1236' }, '\n    ', this.Compressed_Length_B ? (this.Compressed_Length_B * 25.4).toFixed(2) : '0.00', '\n  ') : null, _createElement('td', {}, this.Force ? this.Force : '0'), _createElement('td', {}, this.Force_Newtons ? this.Force_Newtons : '0'), _createElement('td', {}, this.Shaft_Connection ? _createElement('div', {
                                'className': 'table-popup',
                                'key': '1523'
                            }, this.BC_Shaft_Connector_End_Fitting_Type_TN ? _createElement('img', {
                                'src': 'https://' + this.BC_Shaft_Connector_End_Fitting_Type_TN,
                                'key': '1585'
                            }) : null, '\n      ', this.Shaft_Connection?.replaceAll('=', ' '), '\n    ') : null), _createElement('td', {}, this.Body_Connection ? _createElement('div', {
                                'className': 'table-popup',
                                'key': '1806'
                            }, this.BC_Body_Connector_End_Fitting_Type_TN ? _createElement('img', {
                                'src': 'https://' + this.BC_Body_Connector_End_Fitting_Type_TN,
                                'key': '1867'
                            }) : null, '\n      ', this.Body_Connection?.replaceAll('=', ' '), '\n    ') : null), _createElement('td', {}, this.price ? [
                                _createElement('div', {
                                    'className': 'price-section price-section--withoutTax',
                                    'key': '20851'
                                }, _createElement('span', {
                                    'data-product-price-without-tax': true,
                                    'className': 'price price--withoutTax'
                                }, '\n          ', this.formatPrice(this.price), '\n        '), _createElement('small', {}, 'Each'), _createElement('label', {
                                    'className': 'button button--small compare-btn',
                                    'htmlFor': 'compare-' + this.id
                                }, '\n          [+] Compare\n          ', _createElement('input', {
                                    'type': 'checkbox',
                                    'name': 'products[]',
                                    'value': this.id,
                                    'id': 'compare-' + this.id,
                                    'data-compare-id': this.id
                                }))),
                                !this.has_options && !this.out_of_stock ? _createElement('form', {
                                    'action': '/cart.php',
                                    'onSubmit': onSubmit2.bind(this),
                                    'key': '20853'
                                }, _createElement('input', {
                                    'type': 'hidden',
                                    'name': 'action',
                                    'value': 'add'
                                }), _createElement('input', {
                                    'type': 'hidden',
                                    'name': 'product_id',
                                    'value': this.id
                                }), _createElement('label', {}, 'Qty:'), _createElement('input', {
                                    'className': 'form-input form-input--incrementTotal',
                                    'id': 'qty[]',
                                    'name': 'qty[]',
                                    'type': 'tel',
                                    'defaultValue': '1',
                                    'data-quantity-min': '0',
                                    'data-quantity-max': '0',
                                    'min': '1',
                                    'pattern': '[0-9]*',
                                    'aria-live': 'polite'
                                }), _createElement('input', {
                                    'id': 'form-action-addToCart',
                                    'className': 'button button--primary',
                                    'type': 'submit',
                                    'value': this.availability === 'preorder' ? `Pre-Order Now` : `Add to Cart`,
                                    'onClick': onClick3.bind(this)
                                })) : null,
                                this.out_of_stock ? [_createElement('a', {
                                        'href': this.url,
                                        'disabled': true,
                                        'className': 'button button--primary',
                                        'key': '33531'
                                    }, 'Out of stock')] : null,
                                this.has_options ? _createElement('a', {
                                    'className': 'button',
                                    'href': this.custom_url,
                                    'key': '20857'
                                }, 'Choose Options') : null,
                                this.availability_description?.includes('Express shipping') ? _createElement('div', {
                                    'className': 'shipping-icon',
                                    'key': '20859'
                                }, _createElement('img', {
                                    'src': '/product_images/uploaded_images/free-shipping.png',
                                    'title': 'Eligible for Express Shipping'
                                })) : null,
                                this.availability_description?.includes('Usually ships within 24 hours') ? _createElement('div', {
                                    'className': 'shipping-icon',
                                    'key': '208511'
                                }, _createElement('img', {
                                    'src': 'https://portal.liftsupportsdepot.com/images/mini/24icon.png',
                                    'title': this.availability_description
                                })) : null,
                                this.availability_description?.includes('Will ship on or before') ? _createElement('div', {
                                    'className': 'shipping-icon',
                                    'key': '208513'
                                }, _createElement('img', {
                                    'src': 'https://portal.liftsupportsdepot.com/images/mini/calendaricon.png',
                                    'title': this.availability_description
                                })) : null
                            ] : null));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_search-by-part-number__search-results custom-results cm_SearchResult' }, _createElement('table', {}, _createElement('thead', {}, _createElement('tr', {}, _createElement('th', { 'rowSpan': '2' }, 'Interchange'), _createElement('th', { 'rowSpan': '2' }, 'Manufacturer'), _createElement('th', {
                    'rowSpan': '2',
                    'onClick': () => this.setSort('sku')
                }, _createElement('a', {}, 'SKU')), _createElement('th', { 'colSpan': this.view === 'Both' ? 2 : 1 }, _createElement('a', { 'onClick': () => this.setSort('Extended Length A') }, 'Ext.')), _createElement('th', { 'colSpan': this.view === 'Both' ? 2 : 1 }, _createElement('a', { 'onClick': () => this.setSort('Compressed Length B') }, 'Stroke')), _createElement('th', { 'colSpan': this.view === 'Both' ? 2 : 1 }, _createElement('a', { 'onClick': () => this.setSort('Stroke Length C') }, 'Comp.')), _createElement('th', { 'colSpan': '2' }, _createElement('a', { 'onClick': () => this.setSort('Force') }, 'Force')), _createElement('th', { 'rowSpan': '2' }, _createElement('a', { 'onClick': () => this.setSort('Shaft Connection') }, 'Shaft Connector')), _createElement('th', { 'rowSpan': '2' }, _createElement('a', { 'onClick': () => this.setSort('Body Connection') }, 'Body Connector')), _createElement('th', { 'rowSpan': '2' }, _createElement('a', { 'onClick': () => this.setSort('price') }, 'Price'))), _createElement('tr', {}, this.view !== 'Millimeters' ? _createElement('th', { 'key': '1313' }, 'in') : null, this.view !== 'Inches' ? _createElement('th', { 'key': '1381' }, 'mm') : null, this.view !== 'Millimeters' ? _createElement('th', {
                    'className': 'th-red',
                    'key': '1444'
                }, 'in') : null, this.view !== 'Inches' ? _createElement('th', {
                    'className': 'th-red',
                    'key': '1527'
                }, 'mm') : null, this.view !== 'Millimeters' ? _createElement('th', { 'key': '1605' }, 'in') : null, this.view !== 'Inches' ? _createElement('th', { 'key': '1673' }, 'mm') : null, _createElement('th', { 'className': 'th-red' }, 'lbs'), _createElement('th', { 'className': 'th-red' }, 'N'))), _createElement.apply(this, [
                    'tbody',
                    { 'className': 'cmRepeater_items' },
                    _map(this.items, repeatItems1.bind(this))
                ])));
            }, {
                widgetName: 'results',
                items: undefined
            })], [this.SearchHeader(function () {
                return _createElement('div', { 'className': 'cm_search-by-part-number__search-header cm_SearchHeader' }, _createElement('div', { 'className': 'listing-header group' }, _createElement('div', { 'className': 'cm_search-by-size__search-header_left' }, [this.pagination(function () {
                        function repeatButtons1(buttons, buttonsIndex) {
                            return [buttons(function () {
                                    return this.template === 'prev' ? _createElement('li', { 'className': 'cmTemplate_prev' }, _createElement('a', { 'href': this.pageLink }, _createElement('i', {
                                        'className': 'icon',
                                        'aria-hidden': 'true'
                                    }, _createElement('svg', {}, _createElement('use', {
                                        'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                        'xlinkHref': '#icon-chevron-left'
                                    }))))) : this.template === 'next' ? _createElement('li', { 'className': 'cmTemplate_next' }, _createElement('a', { 'href': this.pageLink }, _createElement('i', {
                                        'className': 'icon',
                                        'aria-hidden': 'true'
                                    }, _createElement('svg', {}, _createElement('use', {
                                        'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                                        'xlinkHref': '#icon-chevron-right'
                                    }))))) : this.template === 'page' ? _createElement('li', { 'className': 'cmTemplate_page' }, this.isActive ? _createElement('a', {
                                        'href': this.pageLink,
                                        'className': 'active',
                                        'key': '33'
                                    }, this.page) : null, !this.isActive ? _createElement('a', {
                                        'href': this.pageLink,
                                        'key': '116'
                                    }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_pagination' }, _createElement('label', { 'className': 'form-label' }, 'Pages:'), _createElement.apply(this, [
                            'ul',
                            { 'className': 'custom-pagination cmRepeater_buttons' },
                            _map(this.buttons, repeatButtons1.bind(this))
                        ]));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })], _createElement('div', { 'className': 'cm_unitstoggle sort-fieldset' }, _createElement('label', {
                    'className': 'form-label',
                    'htmlFor': 'sort'
                }, 'Display In:'), _createElement('select', {
                    'className': 'form-select form-select--small form-select-narrow',
                    'onChange': e => this.setView(e.target.value)()
                }, _createElement('option', {
                    'value': 'Inches',
                    'selected': this.view === 'Inches'
                }, 'Inches'), _createElement('option', {
                    'value': 'Millimeters',
                    'selected': this.view === 'Millimeters'
                }, 'Millimeters'), _createElement('option', {
                    'value': 'Both',
                    'selected': this.view === 'Both'
                }, 'Both')))), _createElement('div', { 'className': 'cm_sort sort-fieldset' }, _createElement('label', { 'className': 'form-label' }, 'Sort by: '), '\n    ', this.sortSelect, '\n  '), _createElement('div', { 'className': 'cm_itemsperpage sort-fieldset' }, _createElement('label', { 'className': 'form-label' }, 'SHOW:'), '\n    ', this.pageSizeSelect, '\n  '), [this.facetDialogButton(function () {
                        return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                                return _createElement('div', { 'className': 'button cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                        'className': 'cm_icon cm_filter-icon',
                                        'viewBox': '-5 0 394 394.00003',
                                        'key': '520'
                                    }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                        'className': 'cm_icon cm_filter-icon',
                                        'viewBox': '0 0 247.46 247.46',
                                        'key': '7930'
                                    }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })]);
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]));
            }, {
                widgetName: 'search-header',
                items: undefined
            })])]);
}
        export const componentNames = ["cm:message","cm:searchInput","cm:PartNumberSearchBox","cm:pagination","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:SearchResult","cm:pagination","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader"]