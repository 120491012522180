import type { FC, InputHTMLAttributes } from 'react';

import type { TemplateFunction } from 'Components/types.ts';

export type Params = void;

type Props = {
  template: TemplateFunction<Params>;
  filterValue: string;
  facetName: string;
  facetField: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FilterInput: FC<Props> = ({ template, filterValue, facetName, facetField, onChange }) => {
  const templProps = template()?.props as InputHTMLAttributes<HTMLInputElement>;

  const props = {
    type: 'text',
    autoComplete: 'off',
    className: templProps?.className ?? 'cm_filterInput',
    id: `cm_filter-input-${facetField}`,
    placeholder: `${templProps?.placeholder ?? 'Enter'} ${facetName}...`,
    value: filterValue,
    onChange,
  };
  return <input {...props} />;
};

export default FilterInput;
